import { NuxtAxiosInstance } from "@nuxtjs/axios"
import axios from "axios"

const createCustomAxios = ($axios: NuxtAxiosInstance, $config: any) => {
  const customAxios = axios.create({
    baseURL: $config.bookingInsightsApiUrl,
    headers: $axios.defaults.headers,
    timeout: $axios.defaults.timeout
  })

  return customAxios
}
export const createBookingInsightsApi = ($axios: NuxtAxiosInstance, $config: any) => {
  const customAxios = createCustomAxios($axios, $config)

  return {
    getBookingsCount: (params: BookingsCountParams) =>
      customAxios.get<BookingCountResponse>("/v1/bookings/count", { params }),

    getBookingsMap: (params: BookingsMapParams) =>
      customAxios.get<BookingsMapResponse>("/v1/bookings/map", { params }),

    getBookingsReviews: (params: BookingsReviewsParams) =>
      customAxios.get<ReviewsResponse>("/v1/bookings/reviews", { params }),

    getBookingQuote: (params: BookingQuoteParams) =>
      customAxios.get<BookingQuote>("/v1/bookings/bookingQuote", { params })
  }
}
