import { differenceInSeconds, format } from "date-fns"
import { parse } from "node-html-parser"

/**
 * Function to convert date into time ago
 *
 * @param date string
 * @return string
 */
export function timeAgo(date: string): string {
  if (!date) {
    return "No date provided"
  }

  const periods = ["second", "minute", "hour", "day", "week", "month", "year", "decade"]
  const lengths = ["60", "60", "24", "7", "4.35", "12", "10"]
  const dateObj = new Date(date)
  const nowObj = new Date()

  let difference = 0
  let tense = "ago"
  // is it future date or past date
  if (nowObj > dateObj) {
    difference = differenceInSeconds(nowObj, dateObj)
  } else {
    difference = differenceInSeconds(dateObj, nowObj)
    tense = "from now"
  }

  let j = 0
  for (j = 0; difference >= parseInt(lengths[j]) && j < lengths.length - 1; j++) {
    difference /= parseInt(lengths[j])
  }

  difference = Math.round(difference)

  if (difference != 1) {
    periods[j] += "s"
  }

  return `${difference} ${periods[j]} ${tense}`
}

/**
 * Formats a date with an ordinal day number (e.g., "21st August 2003")
 * @param {Date} date - The date to format
 * @returns {string} The formatted date string with ordinal day number
 * @example
 * const date = new Date('2003-08-21');
 * formatDateWithOrdinal(date); // Returns "21st August 2003"
 */
export const formatDateWithOrdinal = (date: Date): string => {
  const parts = new Intl.DateTimeFormat("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric"
  }).formatToParts(date)

  /**
   * Adds an ordinal suffix to a number (e.g., 1st, 2nd, 3rd, 4th)
   * @param {number} n - The number to add an ordinal suffix to
   * @returns {string} The number with its ordinal suffix
   */
  const ordinal = (n: number): string => {
    const s: string[] = ["th", "st", "nd", "rd"]
    const v: number = n % 100
    return n + (s[(v - 20) % 10] || s[v] || s[0])
  }

  const day = parseInt(parts.find(part => part.type === "day")?.value || "0")
  const month = parts.find(part => part.type === "month")?.value || ""
  const year = parts.find(part => part.type === "year")?.value || ""

  return `${ordinal(day)} ${month} ${year}`
}
/**
 * Function to convert date to a timestamp
 *
 * @param date Date | string
 * @return string
 */
export function formatDateForTimestamp(date?: Date | string): string {
  if (typeof date === "string" || date instanceof String) {
    date = new Date(date)
  }
  return format(date ?? new Date(), "yyyy-MM-dd HH:mm:ss")
}

/**
 * Function to get innerText from an HTML string
 * @param html string
 * @return string
 */
export function getInnerText(html: string): string {
  if (!html) return ""
  const textContentRegex = /<[^>]*>([^<]*)<\/[^>]*>/g
  const matches = html.match(textContentRegex)

  if (!matches) {
    return ""
  }

  return matches
    .map(match => match.replace(textContentRegex, "$1"))
    .join(" ")
    .trim()
}

/**
 * Function to extract the link from WordPress HTML data
 */
export function extractHref(htmlString: string): string | null {
  if (!htmlString) return null
  const pattern = /href=["']([^"']+)["']/
  const match = htmlString.match(pattern)
  return match ? match[1] : null
}

/**
 * Function to extract the image source from WordPress HTML data
 */
export function extractImageUrl(htmlString: string): string | null {
  const regex = /src="([^"]*)"/
  const match = htmlString.match(regex)
  return match ? match[1] : null
}

export function getScreenWidth() {
  if (typeof window !== "undefined") {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  }
  return null
}

export function getv4LocaleFromI18nLocale(i18nLocale: string) {
  switch (i18nLocale) {
    case "en":
      return "en-gb"
    case "es":
      return "es-es"
    case "fr":
      return "fr-fr"
    case "de":
      return "de-de"
    case "it":
      return "it-it"
    default:
      return "en-gb"
  }
}

/**
 * RegEx explanation
 * [a-zA-Z]+:\/\/ Match any protocol
 * [a-zA-Z0-9-]+ Match the first subdomain, e.g. cms-internal
 * (?:\.[a-zA-Z0-9-]+)* Match the domain, e.g. .anyvan. Also, will match any number of subdomains, e.g. dev.cms.internal
 * \.[a-zA-Z]{2,} Match the top-level domain (TLD), e.g. .com
 */
export const matchProtocolAndHost = new RegExp(
  /[a-zA-Z]+:\/\/[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}/g
)

export const replaceUrlS3Helper = (
  url: string,
  destinationHost: string,
  graphqlEndpoint: string
): string => {
  if (!url) {
    return ""
  }

  if (
    graphqlEndpoint.includes("cms.anyvan.local") ||
    graphqlEndpoint.includes("dev-cms.anyvan.com")
  ) {
    return url
  }
  if (url.includes("/app/uploads")) {
    // WordPress media library images that have been auto-uploaded to S3
    return url
      .replace(matchProtocolAndHost, destinationHost)
      .replace(/\/app\/uploads/g, "/content-uploads")
  }

  if (url.includes("/app/themes/anyvan")) {
    // WordPress theme images
    return url
      .replace(matchProtocolAndHost, destinationHost)
      .replace(/\/app\/themes\/anyvan\/assets\/img/g, "/content-static/wp-theme")
  }

  return url
}

/**
 * Converts a blob of HTML to pure text that is easily consumed by a human.
 * The original use was to ingest HTML from the CMS and format it into
 * schema.org metadata. This metadata will end up in front of a user/lead,
 * so it has to be formatted nicely with spaces where you expect etc.
 *
 * @param text
 */
export function prettyStripHTML(text: string): string {
  const root = parse(text)

  // Add periods to list items that don't have them
  const listItems = root.querySelectorAll("li")
  listItems.forEach(item => {
    const text = item.textContent.trim()
    if (!text.endsWith(".") && !text.endsWith("!") && !text.endsWith("?")) {
      item.set_content(text + ".")
    }
  })

  // Add spaces after paragraphs and before lists
  const paragraphs = root.querySelectorAll("p")
  const lists = root.querySelectorAll("ul, ol")
  ;[...paragraphs, ...lists].forEach(el => {
    el.insertAdjacentHTML("afterend", " ")
  })

  let content = root.textContent

  // Clean up whitespace
  content = content
    .replace(/[\r\n\t]+/g, " ")
    .replace(/\s{2,}/g, " ")
    .trim()

  // Replace any potential JSON-escape-prone characters
  return content.replace(/'/g, "'").replace(/"/g, '"')
}

/**
 * Replace placeholders in CMS blocks with dynamic content.
 *
 * @param {Block} block - The CMS block object to process.
 * @param {Object.<string, string>} replacementData - Data to dynamically inject
 * into CMS blocks.
 *
 * @returns {Block} A new block object with placeholders replaced in `innerHtml`, `attributes`, and
 * recursively in `innerBlocks`.
 */
export const processBlock = (block: Block, replacementData: Dictionary): Block => {
  const processedBlock = { ...block }

  if (block.innerHtml) {
    processedBlock.innerHtml = block.innerHtml.replace(/\{([^}]+)\}/g, (match, key) => {
      const propertyKey = key.toLowerCase()
      return replacementData[propertyKey] || match
    })
  }

  if (block.attributes) {
    processedBlock.attributes = block.attributes.map(attr => ({
      ...attr,
      value: attr.value.replace(/\{([^}]+)\}/g, (match, key) => {
        const propertyKey = key.toLowerCase()
        return replacementData[propertyKey] || match
      })
    }))
  }

  if (block.innerBlocks && block.innerBlocks.length) {
    processedBlock.innerBlocks = block.innerBlocks.map(innerBlock =>
      processBlock(innerBlock, replacementData)
    )
  }

  return processedBlock
}
